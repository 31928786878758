import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
/* --- import Components ----*/
import { Section, TwoColumns } from '../Section';
import { SectionTitle, Paragraph, StyledLinkA } from 'src/components/Typography';
import { SectionHeaderBlock } from 'src/components/Section/elements';
/* --- import Styles ----*/
import { Line } from 'src/style/lines';
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';

const DiplomaSection = React.memo(({ theme, isAlternance, diploma }) => {
  return (
    <Section theme={theme || 'light'}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionHeaderBlock css={{ maxWidth: 1045, marginBottom: 40 }}>
          <SectionTitle isMobileCenter isCenter>
            Une formation{' '}
            <span css={{ color: colors.purple }}>
              {diploma === 'rncp5' ? 'de niveau Bac+2' : 'certifiante Bac+3/4'}
            </span>
            <br />
          </SectionTitle>
        </SectionHeaderBlock>
      </div>

      <TwoColumns
        sizeLeft={6}
        left={
          <div
            css={{
              [mediaQueries.tabletLandscapeUp]: {
                marginLeft: 35,
              },
            }}
          >
            <Paragraph>
              Le Reacteur propose{' '}
              <span css={{ fontWeight: 'bold' }}>
                {diploma === 'rncp5'
                  ? 'une formation'
                  : isAlternance
                  ? 'une formation en alternance'
                  : 'un bootcamp'}
              </span>{' '}
              {diploma === 'rncp5'
                ? ` qui vous permet d'obtenir :`
                : ` qui vous forme à un métier ${
                    isAlternance ? 'sur 15 à 17 mois' : ''
                  } et qui vous permet d'obtenir:`}
            </Paragraph>
            <ul>
              <li
                css={{
                  display: 'flex',
                  alignItems: 'start',
                }}
              >
                <Line css={{ marginTop: 12, minWidth: 22 }} />
                {diploma === 'rncp5' ? (
                  <Paragraph css={{ paddingTop: 0 }}>
                    Le{' '}
                    <StyledLinkA
                      color={colors.purple}
                      target="_blank"
                      href={'https://www.lereacteur.io/titre-rncp-developpeur-web'}
                    >
                      titre RNCP de Niveau 5 (Bac+2) Développeur Web
                    </StyledLinkA>
                    , certifié et délivré par <strong>Webecom</strong> (des frais de passage
                    d’examen sont à prévoir).<br></br>Ce titre est référencé au répertoire national
                    des certifications professionnelles, sous le numéro RNCP37805, en date du
                    19/07/2023.<br></br>Aucun diplôme préalable n'est requis. Nos formations sont
                    ouvertes à tous !
                  </Paragraph>
                ) : (
                  <Paragraph css={{ paddingTop: 0 }}>
                    Le{' '}
                    <StyledLinkA
                      color={colors.purple}
                      target="_blank"
                      href={
                        'https://www.lereacteur.io/titre-rncp-concepteur-developpeur-d-applications'
                      }
                    >
                      titre RNCP de Niveau 6 (Bac+3/4) Concepteur Développeur d'Applications
                    </StyledLinkA>
                    , certifié par l'État et délivré par le Ministère du Travail . Aucun diplôme
                    préalable n'est requis. Nos formations diplômantes' sont ouvertes à tous !
                  </Paragraph>
                )}
              </li>
            </ul>
            <Paragraph>
              Grâce à cette <span css={{ fontWeight: 'bold' }}>certification</span>, vous pourrez :
            </Paragraph>
            <ul>
              {!isAlternance ? (
                <li
                  css={{
                    display: 'flex',
                    alignItems: 'start',
                  }}
                >
                  <Line css={{ marginTop: 12, minWidth: 22 }} />
                  <Paragraph css={{ paddingTop: 0, paddingBottom: 5 }}>
                    <span css={{ fontWeight: 'bold' }}>Financer la formation </span>
                    grâce à votre compte personnel de formation (CPF), l'AIF (France Travail, ex
                    Pôle Emploi), les Régions, Transitions Pro, etc.
                  </Paragraph>
                </li>
              ) : (
                ''
              )}
              <li
                css={{
                  display: 'flex',
                  alignItems: 'start',
                }}
              >
                <Line css={{ marginTop: 12, minWidth: 22 }} />
                <Paragraph css={{ paddingTop: 0 }}>
                  <span css={{ fontWeight: 'bold' }}>Être crédible sur le marché du travail </span>
                  en sortant d'un organisme connu et reconnu pour sa qualité d'enseignement, sa
                  notation sur les différentes plateformes comparatives ainsi que pour avoir été le
                  premier organisme à proposer un bootcamp Web et Mobile en France.
                </Paragraph>
              </li>
            </ul>
          </div>
        }
        sizeRight={1}
        right={
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              [mediaQueries.tabletLandscapeUp]: {
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'left',
                marginLeft: 10,
              },
              [mediaQueries.phoneOnly]: {
                marginBottom: 20,
              },
            }}
          >
            <StaticImage
              css={{
                width: '30%',
                display: diploma === 'rncp5' ? 'none' : 'block',
                [mediaQueries.tabletLandscapeUp]: {
                  height: 180,
                  width: 168,
                  marginBottom: 20,
                },
              }}
              imgStyle={{
                verticalAlign: 'middle',
                objectFit: 'contain',
              }}
              src="../../../src/data/images/partners/ministere-travail-test.jpg"
              alt="logo du ministère du travail, de l'emploi et de l'insertion"
              // placeholder="none"
            />
            <StaticImage
              css={{
                width: '30%',
                [mediaQueries.tabletLandscapeUp]: {
                  height: 150,
                  width: 220,
                },
              }}
              imgStyle={{
                verticalAlign: 'middle',
                objectFit: 'contain',
              }}
              src="../../../src/data/images/partners/logo_france_competences_.png"
              alt="logo de France compétences"
              // placeholder="none"
            />
          </div>
        }
      />
      {isAlternance ? (
        <Paragraph
          css={{
            textAlign: 'center',
            fontSize: 12,
            lineHeight: '18px',
            paddingTop: 40,
            [mediaQueries.phoneOnly]: { fontSize: 12, lineHeight: '18px' },
            [mediaQueries.tabletLandscapeUp]: {
              paddingTop: 20,
            },
          }}
        >
          Nos premiers alternants qui ont débuté en 2023 termineront leur cursus fin 2024. À ce
          titre, d'ici début 2025, seront communiqués : le taux d'obtention des titres
          professionnels, le taux de poursuite d'études, le taux d'interruption en cours de
          formation, le taux d'insertion professionnelle des sortants de l'établissement, à la suite
          de la formation et enfin la valeur ajoutée de l'établissement.
        </Paragraph>
      ) : null}
    </Section>
  );
});

export default DiplomaSection;
